var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"page__wrap"},[_c('div',{staticClass:"page__content"},[_c('div',{staticClass:"sidebar__header"},[_c('h1',{staticClass:"sidebar__title"},[_vm._v("Sugestão de material")]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button_outline",attrs:{"type":"button"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Cadastrar sugestão ")])])]),_c('div',{staticClass:"tabs__flex"},[_vm._m(0),_c('div',{staticClass:"tabs__search"},[_c('form',{staticClass:"search__form"},[_c('input',{staticClass:"form__input",attrs:{"type":"text","placeholder":"Pesquisar..."},on:{"input":_vm.debounceInput}}),_vm._m(1)])])]),_c('div',{staticClass:"tab__body current",attrs:{"id":"all"}},[_c('table',{staticClass:"table"},[_vm._m(2),_c('tbody',_vm._l((_vm.clients),function(client){return _c('tr',{key:client.id},[_c('td',{staticClass:"flex-td"},[_c('span',[_vm._v(" "+_vm._s(client.name))])]),_c('td',[_c('span',[_vm._v(_vm._s(client.unity_name))])]),_c('td',[_c('span',[_vm._v(_vm._s(client.category_name))])]),_c('td',[_c('span',{staticClass:"alert_card",style:(client.status == -1
                          ? 'background-color: #fff3e2; color: #d87420;'
                          : client.status == 0
                          ? 'background-color: #E0F8E6; color: #16b666;'
                          : 'background-color: #FFE6E0; color: #FF371A;')},[_vm._v(" "+_vm._s(client.status == -1 ? 'Em análise' : client.status == 0 ? 'Aprovado' : 'Reprovado')+" ")])]),_c('td',[_c('i',{staticClass:"fas fa-edit edit_icon",on:{"click":function($event){return _vm.openEditModal(client)}}}),_c('i',{staticClass:"far fa-trash-alt delete_icon",on:{"click":function($event){return _vm.deleteClient(client.id)}}})])])}),0)])])])]),_c('paginator',{directives:[{name:"show",rawName:"v-show",value:(_vm.clients.length),expression:"clients.length"}],attrs:{"pages":_vm.pagination.pages},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)]),_c('modal-register',{attrs:{"editing":_vm.editing,"modal":_vm.modal},on:{"update:editing":function($event){_vm.editing=$event},"update:modal":function($event){_vm.modal=$event},"change-modal":e => (_vm.modal = e),"reload-list":_vm.getClients}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs__nav"},[_c('div',{staticClass:"alert"},[_c('p',[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" Após aprovação, o material ficará disponível para adicionar em suas coletas. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"button search__button",attrs:{"type":"submit"}},[_c('i',{staticClass:"icon icon-search"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Nome")]),_c('th',[_vm._v("Forma")]),_c('th',[_vm._v("Categoria")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Opções")])])])
}]

export { render, staticRenderFns }